/* website: 3139-greatlakeshonda
 * created at 2020-07-10 15:47 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/honda.scss";
@import "../utils/icons.scss";


.widget-sr{
    &.dealer__great-lakes-honda{
        .sr-topbar-login{
            background-color: #000000;
        }
    }
  }